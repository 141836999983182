import React, { useState } from 'react';


import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"

const Chat = () => {
    const [isChatopen, setIsChatopen] = useState(false);
    return (
        <div className="chat-content">
            <div className="chat-content-circle">
                {
                    !isChatopen &&
                    <span onClick={() => setIsChatopen(true)} className="comment-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                            <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                    <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </span>
                }

                {
                    isChatopen &&
                    <span onClick={() => setIsChatopen(false)} className="close-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.973" height="29.602" viewBox="0 0 32.973 29.602">
                            <g id="Group_317" data-name="Group 317" transform="translate(-1821 -993)">
                                <g id="Group_14" data-name="Group 14" transform="translate(-18 146)">
                                    <line id="Line_1" data-name="Line 1" y1="24" x2="24" transform="translate(1843.5 850.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
                                    <line id="Line_2" data-name="Line 2" x1="24" y1="24" transform="translate(1843.5 850.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
                                </g>
                                <g id="Group_13" data-name="Group 13" transform="translate(100.5 206.5)">
                                    <path id="Union_3" data-name="Union 3" d="M22.613,24.944a16.213,16.213,0,0,1-7.651,1.884C6.7,26.828,0,20.822,0,13.414S6.7,0,14.962,0,29.924,6.006,29.924,13.414a12.559,12.559,0,0,1-3.461,8.58A9.1,9.1,0,0,0,30,27.344a3.7,3.7,0,0,1-1.505.258A8.566,8.566,0,0,1,22.613,24.944Z" transform="translate(1721.5 787.5)" fill="none" stroke="#fff" strokeWidth="2" opacity="0" />
                                    <g id="Group_12" data-name="Group 12" transform="translate(1729.722 799.746)" opacity="0">
                                        <circle id="Ellipse_9" data-name="Ellipse 9" cx="1.685" cy="1.685" r="1.685" fill="#fff" />
                                        <circle id="Ellipse_10" data-name="Ellipse 10" cx="1.685" cy="1.685" r="1.685" transform="translate(5.054)" fill="#fff" />
                                        <circle id="Ellipse_11" data-name="Ellipse 11" cx="1.685" cy="1.685" r="1.685" transform="translate(10.109)" fill="#fff" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                }

            </div>
            {
                isChatopen &&
                <div className="chat-box">

                    <div className="chatbox-inner">
                        <div className="chat-profile">
                            <img className="" src={chatProfile} alt="" />
                        </div>
                        <span onClick={() => setIsChatopen(false)} className="close-chat"><img className="" src={closeChat} alt="" /></span>
                        <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
                    </div>
                    <img className="chat-bottom" src={chatbottom} alt="" />
                </div>
            }

        </div>
    )
}

export default Chat
