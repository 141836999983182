import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import "./header.css"
import Logo from "../images/uat-sc-logo.png"
// import search from "../images/search.svg"
import menuIcon from "../images/menu-icon.svg"
import closeIcon from "../images/close-icon.svg"
import help from "../images/questions-icon.svg"
import refresh from "../images/refresh-icon.svg"
import call from "../images/call-icon-1.svg"
import userIcon from "../images/user-icon.svg"
import infoIcon from "../images/info.svg"
import { isAuthenticated, getCurrentPath, mylogout } from "../core/util";
import * as actionCreators from './../store/actions';

import notification from "../images/profile/notification.svg"


class Header extends Component {
  state = {
    loading: false,
    isMobileMenuopen: false,
    isLogout: false,
    dropdownMenu: false,
    dealertoken:'load',
  }
  componentDidMount = () => {

    /* const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://tracker.gaconnector.com/gaconnector.js";
    script2.setAttribute('data-cfasync', 'false');
    document.head.appendChild(script2); */

    let host = "direct";    
    let qs = this.getUrlVars();
    
    

    let cpage = window.location.pathname.replace(/\//g,'');
    if(!cpage) cpage = 'Home';

    
    global.dealertoken = '';
    if(qs.dealertoken){
      global.dealertoken = qs.dealertoken;
      if(qs.href){
        global.href = decodeURIComponent(qs.href).split('#').shift();
      }
      //console.log('----------'+global.href)
      this.setState({ dealertoken: qs.dealertoken });
      this.state.dealertoken = qs.dealertoken;
    } else {
      this.setState({ dealertoken: '' });
      this.state.dealertoken = '';
      if(!localStorage.utmData){ // New User
        sessionStorage.startAt = new Date().getTime();
        let device = "Desktop";
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) ) {
          device = "Mobile";
        }
        if (document.referrer) {
          host = document.referrer.match(/:\/\/(.[^/]+)/)[1];
        }
        let utmData = {
          userId : new Date().getTime(),
          GA_Client_ID:this.getCookie('_ga'),
          time_zone : new Date().toString().match(/\((.*)\)/).pop(),
          device : device,
          OS:this.getOS(),
          browser:this.getBrowser(),
          time_passed:1,
          page_visits:1,
          pages_visited_list : cpage,
          country :'',
          country_code:'',
          region:'',
          city:'',
          latitude:'',
          longitude:'',
          ip:'',
          fc_referrer : host,
          lc_referrer : host,
          fc_source:qs.utm_source?qs.utm_source:'direct',
          fc_medium:qs.utm_medium?qs.utm_medium:'',
          fc_campaign:qs.utm_campaign?qs.utm_campaign:'',
          fc_term:qs.utm_term?qs.utm_term:'',
          fc_content:qs.utm_content?qs.utm_content:'',
          fc_channel:qs.utm_medium?'Pay-Per-Click':'',
          fc_landing:cpage,
          lc_landing:cpage,
          lc_source:qs.utm_source?qs.utm_source:'direct',
          lc_medium:qs.utm_medium?qs.utm_medium:'',
          lc_campaign:qs.utm_campaign?qs.utm_campaign:'',
          lc_term:qs.utm_term?qs.utm_term:'',
          lc_content:qs.utm_content?qs.utm_content:'',
          lc_channel:qs.utm_medium?'Pay-Per-Click':'',
          all_traffic_sources : qs.utm_medium?qs.utm_medium:host,
          last_ad_click : 0,
          ad_lead_gen : 0
        }
        if(qs.utm_medium){
          let newdt = new Date();
          utmData.last_ad_click = Math.round(newdt.getTime() / 1000);
          let mm = newdt.getMonth()+1; 
          let dt = newdt.getDate();
          utmData.keylist = dt+''+mm+'-'+qs.utm_term;
        } else {
          utmData.keylist ='';
        }
        this.setUtmData(utmData)
        this.SetIPData();
      } else if(!sessionStorage.startAt){ // New Session
        sessionStorage.startAt = new Date().getTime();
        if (document.referrer) {
          host = document.referrer.match(/:\/\/(.[^/]+)/)[1];
        }

        let utmData = this.getUtmData();
        utmData.lc_source = qs.utm_source?qs.utm_source:'direct';
        utmData.lc_medium = qs.utm_medium?qs.utm_medium:'';
        utmData.lc_campaign = qs.utm_campaign?qs.utm_campaign:'';
        utmData.lc_term = qs.utm_term?qs.utm_term:'';
        utmData.lc_content = qs.utm_content?qs.utm_content:'';
        utmData.lc_channel = qs.utm_medium?'Pay-Per-Click':'';
        utmData.lc_landing = cpage;
        utmData.lc_referrer = host;
        utmData.page_visits = utmData.page_visits + 1;
        utmData.pages_visited_list = utmData.pages_visited_list.substr(-100) + '|' + cpage;
        utmData.all_traffic_sources = utmData.all_traffic_sources + '|' + (qs.utm_medium?qs.utm_medium:host);
        if(qs.utm_medium){
          let newdt = new Date();
          utmData.last_ad_click = Math.round(newdt.getTime() / 1000);
          let mm = newdt.getMonth()+1; 
          let dt = newdt.getDate();
          utmData.keylist = utmData.keylist+'|'+dt+''+mm+'-'+qs.utm_term;
        }
        this.setUtmData(utmData)
      } else {
        let utmData = this.getUtmData();
        if(qs.utm_medium) {
          utmData.lc_source = qs.utm_source?qs.utm_source:'direct';
          utmData.lc_medium = qs.utm_medium?qs.utm_medium:'';
          utmData.lc_campaign = qs.utm_campaign?qs.utm_campaign:'';
          utmData.lc_term = qs.utm_term?qs.utm_term:'';
          utmData.lc_content = qs.utm_content?qs.utm_content:'';
          utmData.lc_channel = qs.utm_medium?'Pay-Per-Click':'';
          utmData.lc_landing = cpage;
          utmData.lc_referrer = host;
          utmData.all_traffic_sources = utmData.all_traffic_sources + '|' + (qs.utm_medium?qs.utm_medium:host);
          let newdt = new Date();
          utmData.last_ad_click = Math.round(newdt.getTime() / 1000);
          let mm = newdt.getMonth()+1; 
          let dt = newdt.getDate();
          utmData.keylist = utmData.keylist+'|'+dt+''+mm+'-'+qs.utm_term;
        }
        utmData.page_visits = utmData.page_visits + 1;
        utmData.pages_visited_list = utmData.pages_visited_list.substr(-100) + '|' + cpage;
        utmData.time_passed = utmData.time_passed + Math.round((new Date().getTime() - sessionStorage.startAt) / 1000);
        this.setUtmData(utmData)
      }
    }
  }
  getUtmData(){
    if(localStorage.utmData.includes("GA_Client_ID")){
      //console.log(JSON.parse(localStorage.utmData))
      return JSON.parse(localStorage.utmData)
    } else {
      //console.log(JSON.parse(window.atob(localStorage.utmData)))
      return JSON.parse(window.atob(localStorage.utmData))
    }
  }
  setUtmData(json){
    localStorage.setItem("utmData", window.btoa(JSON.stringify(json)));
  }
  getUrlVars() {
      var qs = {};
      window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
          qs[key] = decodeURI(value);
      });
      if(!qs.utm_source) {
        if (document.referrer) {
          qs.utm_source = document.referrer.match(/:\/\/(.[^/]+)/)[1];
          qs.channel = 'organic';
        }
      }
      return qs;
  }

  SetIPData(){
    fetch('https://extreme-ip-lookup.com/json/').then(res => res.json()).then((res) => {
        if (res.status === 'success') {
          let utmData = this.getUtmData();
          utmData.country =res.country;
          utmData.country_code=res.countryCode;
          utmData.region=res.region;
          utmData.city=res.city;
          utmData.latitude=res.lat;
          utmData.longitude=res.lon;
          utmData.ip=res.query;
          this.setUtmData(utmData)
        }
    });
  }

  getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }
  
  getOS(){
    var OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0")!== -1) OSName="Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1) OSName="Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1) OSName="Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1) OSName="Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1) OSName="Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1) OSName="Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac")            !== -1) OSName="Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11")            !== -1) OSName="UNIX";
    if (window.navigator.userAgent.indexOf("Linux")          !== -1) OSName="Linux";
    return OSName;
  }
  getBrowser(){
    var ua= window.navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!== null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!== null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  doLogout(isHideNavbar) {
    this.setState({ isLogout: true });
    mylogout();
    if (isHideNavbar) {
      this.setState({ isMobileMenuopen: false });
      this.props.menuToggle(false);
    }

    if (this.props && this.props.rest && this.props.rest.location && this.props.rest.location.pathname === "/") {
      this.props.logout();
    } else {
      navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''), {
        state: { isLogout: true },
      })
    }
  }

  doRefresh() {
    if (window.location.pathname.includes('agreement')) {
      sessionStorage.clear();
      this.props.clearAllSteps();
      navigate("/reload2")
    } else {
      
      this.props.clearAllSteps();
      navigate("/reload")
    }
  }

  registerActiveClass = () => {
    let currentPath = getCurrentPath();
    if ((currentPath.includes('vehicleInfo') ||
      currentPath.includes('yourInfo') ||
      currentPath.includes('customizePlan') ||
      currentPath.includes('createAccount') ||
      currentPath.includes('securePayment') ||
      currentPath.includes('confirm'))) {
      return 'active';
    } else {
      return '';
    }
  }

  render() {
    const { layoutType, rest} = this.props;
    const {dealertoken} = this.state;
    
    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent
        ? { className: "active" }
        : null
    }

    let location = '';
    if (rest && rest.location) {
      location = rest.location;
    }
    const { isLogout } = this.state;

    let registerActiveClass = this.registerActiveClass();
    
    
    return (
      <header id="pageHeader">
        {/* {!dealertoken && layoutType!=="nds" &&
        <div className="mfh">
        We are here for you during the COVID-19 pandemic. Our team is working from home to ensure you get the help you need.
        </div>
        } */}
        {/* --------main header------ */}
        {
          layoutType === 'main' && dealertoken==='' &&
          <div className="inner-header">
            <div className="header-content">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
              {/* -------- first page menu--------- */}
              <div className="nav-btn">
                <ul>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/vehicleInfo/">Get Your Quote</Link></li>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/covered">Coverage Plans</Link></li>
                 {/*  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/benefits">Benefits</Link></li>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/claims">Claims</Link></li> */}
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/faq">FAQs</Link></li>
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/api">API</Link></li> */}
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/partners">Partners</Link></li> 
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/who-we-are">Who We Are</Link></li>*/}
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/contact">Contact</Link></li>
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/blog">Blog</Link></li> */}

                  {/* <li className="search-icon"><Link to=""><img src={search} alt="search" /></Link></li> */}
                </ul>
              </div>
              <div className="login-btn">
                {
                  isAuthenticated() && !isLogout ?
                    <div className="myProfile-con">
                      <span className="btn primary account-btn" onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })}>My Account</span>
                      <span onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })} className="user-icon"><img src={userIcon} alt="user" /></span>
                      {
                        this.state.dropdownMenu &&
                        <div className="myProfile-box" >
                          <ul>
                            <li><Link to="/userprofile" className="change-pass btn1">My Profile</Link></li>
                            <li><Link to="/change-password" className="change-pass btn1">Change Password</Link></li>
                            <li><span onClick={() => this.doLogout(false)} className="logout btn1">Logout</span></li>
                          </ul>
                        </div>
                      }

                    </div>
                    :
                    <>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="btn primary">Login</Link>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="user-icon"><img src={userIcon} alt="user" /></Link>
                    </>
                }

                <div className="mobile-menu-right">
                  {/* <span className="search right-icon"><img src={search} alt="search" /></span> */}
                  <span className="menu-icon right-icon" onClick={() => {this.setState({ isMobileMenuopen: true }); this.props.menuToggle(true)} }  ><img src={menuIcon} alt="menu" /></span>
                </div>
              </div>
            </div>

            {/* -------- first page menu--------- */}
          </div>
        }
        {/* --------main header------ */}

        {/* --------wizard header------ */}
        {
          layoutType === 'wizard' && dealertoken==='' &&
          <div className="inner-header">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="right wizard-header-right">
              <span className="call-text"><img src={call} alt="help" /></span>
              <p className="call-text">1-800-800-3629</p>
              <span onClick={() => this.doRefresh()} className="cursor-pnt"><img src={refresh} alt="refresh" /></span>
              <Link to="/contact"><img src={help} alt="help" /></Link>
              <span onClick={() => {this.setState({ isMobileMenuopen: true }); this.props.menuToggle(true)}} ><img src={menuIcon} alt="menu" /></span>
            </div>
          </div>
        }
        {/* --------wizard header------ */}


        {/* --------contact header------ */}
        {
          layoutType === 'contactHeader' &&
          <div className="inner-header contact-header">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="right">
              <div className="login-btn">
                {
                  isAuthenticated() && !isLogout ?
                    <div className="myProfile-con">
                      <span className="btn primary" onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })}>My Account</span>
                      <span onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })} className="user-icon"><img src={userIcon} alt="user" /></span>
                      {
                        this.state.dropdownMenu &&
                        <div className="myProfile-box" >
                          <ul>
                            <li><Link to="/userprofile" className="change-pass btn1">My Profile</Link></li>
                            <li><Link to="/change-password" className="change-pass btn1">Change Password</Link></li>
                            <li><span onClick={() => this.doLogout(false)} className="logout btn1">Logout</span></li>
                          </ul>
                        </div>
                      }

                    </div>
                    :
                    <>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="btn primary">Login</Link>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="user-icon"><img src={userIcon} alt="user" /></Link>
                    </>
                }

                <div className="contact-menu-right">
                  {/* <span className="search right-icon"><img src={search} alt="search" /></span> */}
                  <span className="menu-icon right-icon" onClick={() => {this.setState({ isMobileMenuopen: true }); this.props.menuToggle(true)}}  ><img src={menuIcon} alt="menu" /></span>
                </div>
              </div>
            </div>
          </div>
        }
        {/* --------contact header------ */}


        {/* --------blog details header------ */}
        {
          layoutType === 'blogHeader' &&
          <div className="inner-header contact-header">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="nav-btn blogdetails-nav-btn">
              <ul>
                {/* <li><Link activeClassName="active" to="/blog">News & Media</Link></li> */}
                <li><Link to="/blog">News & Media</Link></li>
                <li><Link to="/blog"> Trends</Link></li>
                <li><Link to="/blog">Tips</Link></li>
              </ul>
            </div>
            <div className="right">
              <div className="login-btn">
                {
                  isAuthenticated() && !isLogout ?
                    <div className="myProfile-con">
                      <span className="btn primary" onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })}>My Account</span>
                      <span onClick={() => this.setState({ dropdownMenu: !this.state.dropdownMenu })} className="user-icon"><img src={userIcon} alt="user" /></span>
                      {
                        this.state.dropdownMenu &&
                        <div className="myProfile-box" >
                          <ul>
                            <li><Link to="/userprofile" className="change-pass btn1">My Profile</Link></li>
                            <li><Link to="/change-password" className="change-pass btn1">Change Password</Link></li>
                            <li><span onClick={() => this.doLogout(false)} className="logout btn1">logout</span></li>
                          </ul>
                        </div>
                      }

                    </div>
                    :
                    <>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="btn primary">Login</Link>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="user-icon"><img src={userIcon} alt="user" /></Link>
                    </>
                }

                <div className="contact-menu-right">
                  {/* <span className="search right-icon"><img src={search} alt="search" /></span> */}
                  <span className="menu-icon right-icon" onClick={() => {this.setState({ isMobileMenuopen: true }); this.props.menuToggle(true)}}  ><img src={menuIcon} alt="menu" /></span>
                </div>
              </div>
            </div>
          </div>
        }
        {/* --------blog details header------ */}

        
        {/* --------profile header------ */}
        {
          layoutType === 'nohead' &&
          <div class="container"><div onClick={() => this.doLogout(false)} className="noheadlogout">Logout</div></div>
          
        }
        {
          layoutType === 'profileHeader' && dealertoken==='' &&
          <div className="inner-header contact-header">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="right">
              <div className="login-btn">
                <span className="notification-icon cursor-pnt hide"><span className="notification-circle">2</span><img src={notification} alt="notification" /></span>
                {
                  isAuthenticated() && !isLogout ?
                    <div className="myProfile-con">
                      <span onClick={() => this.doLogout(false)} className="btn primary">Logout</span>
                    </div>
                    :
                    <>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="btn primary">Login</Link>
                      <Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} className="user-icon"><img src={userIcon} alt="user" /></Link>
                    </>
                }

                <div className="contact-menu-right">
                  {/* <span className="search right-icon"><img src={search} alt="search" /></span> */}
                  <span className="menu-icon right-icon" onClick={() => {this.setState({ isMobileMenuopen: true }); this.props.menuToggle(true)}}  ><img src={menuIcon} alt="menu" /></span>
                </div>
              </div>
            </div>
          </div>
        }
        {/* --------profile header------ */}


        {
          this.state.isMobileMenuopen &&
          <div className="mobile-menu">
            <div className="mobile-nav-btn">
              <span className="close-btn" onClick={() => {this.setState({ isMobileMenuopen: false }); this.props.menuToggle(false)}} ><img src={closeIcon} alt="close" /></span>
              <ul>
                <li><Link activeClassName="active" className={registerActiveClass} getProps={isPartiallyActive} to="/vehicleInfo/">Get Your Quote</Link></li>
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/covered">Coverage Plans</Link></li>
                {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/benefits">Benefits</Link></li>
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/claims">Claims</Link></li> */}
                <li><a href="/dealer/" target="_blank">Dealer</a></li>
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/faq">FAQs</Link></li>
                {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/api">API</Link></li>
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/partners">Partners</Link></li> 
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/who-we-are">Who We Are</Link></li>*/}
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/contact">Contact</Link></li>
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/legalstuff">Legal Stuff</Link></li>
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/privacy">Privacy Policy</Link></li>
                <li><Link activeClassName="active" getProps={isPartiallyActive} to="/terms">Terms of Service</Link></li>
               {/*  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/blog">Blog</Link></li> */}
                {
                  isAuthenticated() && !isLogout ?
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/userprofile">My Account</Link></li>
                  : ''
                }
                {
                  isAuthenticated() && !isLogout ?
                  <li><span onClick={() => this.doLogout(false)} className="btn primary">Logout</span></li>
                  :
                  <li><Link to="/login" state={{ prevPath: location && location.pathname ? location.pathname : '' }} activeClassName="active">Login</Link></li>
                }
                
                
                {/* <li className="new-btn"><Link activeClassName="active" to="/blog">Join the Team</Link></li> */}
                

                {/* <li className="new-btn"><Link activeClassName="active" getProps={isPartiallyActive} to="/legalstuff">Legal Stuff</Link></li>
                <li className="new-btn"><Link activeClassName="active" getProps={isPartiallyActive} to="/privacy">Privacy Policy</Link></li>
                <li className="new-btn"><Link activeClassName="active" getProps={isPartiallyActive} to="/terms">Terms of Service</Link></li> */}
              </ul>
              {/* <div className="follow-icon">
                <span><img src={fbIcon} alt="facebook" /></span>
                <span><img src={twitterIcon} alt="Twitter" /></span>
                <span><img src={instacon} alt="instagra," /></span>
                <span><img src={video} alt="youtube" /></span>
              </div> */}
            </div>
          </div>
        }
        
      </header >
    )

  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
    clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

